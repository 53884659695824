import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { graphql, PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import { getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import { CheckCircle } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import Layout from '../components/layout'

type BaedekerSmartProps = { data: GatsbyTypes.SmartPageQuery } & PageProps

const title = 'Baedeker.SMART'
const description =
  'EINFACH UND SCHNELL PLANEN – UND GANZ BESONDERE URLAUBSTAGE ERLEBEN:\n' +
  'Morgens schnell und unkompliziert den Tag planen – mit dem Baedeker SMART haben Sie den perfekten Reiseführer an Ihrer Seite, auf den Sie sich verlassen können und der die schönste Zeit des Jahres unvergesslich macht'
const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  image: {
    width: '100%',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  centerGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
  },
}))

const BaedekerSmart = ({
  location,
  data: { metaImage },
}: BaedekerSmartProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const metaImageData =
    metaImage &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(metaImage)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { height, width } = metaImageData

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const imagePath = getSrc(metaImage)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('content')
    }
  }, [])
  return (
    <Layout
      location={location}
      seoProps={{
        title,
        description,
        images: [
          {
            height,
            width,
            alt: '',
            caption: '',
            path: imagePath ?? '',
          },
        ],
      }}
    >
      <Typography component="h1" variant="h2" gutterBottom>
        {title}
      </Typography>
      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={8} lg={6} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/images/content/home/book2.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>

          <Grid item xs={12} lg={6} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              SMART reisen – Zeit genießen{' '}
            </Typography>

            <Typography variant="body1" gutterBottom>
              Einfach und schnell planen – und ganz besondere Urlaubstage
              erleben: Morgens schnell und unkompliziert den Tag planen – mit
              dem Baedeker SMART haben Sie den perfekten Reiseführer an Ihrer
              Seite, auf den Sie sich verlassen können und der die schönste Zeit
              des Jahres unvergesslich macht
            </Typography>

            <List dense>
              {[
                'Detaillierte Reiseziel-Beschreibungen',
                'Gegliedert nach Regionen',
                'Alle Sehenswürdigkeiten in drei Rubriken: TOP 10 -- Nicht verpassen -- Nach Lust und Laune',
                'Handliches Format: einfach einstecken',
                'Exklusive Spiralbindung: Wunschseite im Blick behalten',
                'Hochwertige Veredelung und Ausstattung',
                'Atlasteil und herausnehmbare Faltkarte',
              ].map((text) => (
                <ListItem key={text}>
                  <ListItemIcon>
                    <CheckCircle style={{ color: green[500] }} />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={12} lg={7} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Mein Tag
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Die „Kleine Reise in der Reise“:
            </Typography>
            <Typography>
              Inspirierende Tagestouren in jedem Kapitel. Mit detaillierter
              Karte
            </Typography>
          </Grid>
          <Grid item xs={8} lg={5} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/Mein Tag.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>
        </Grid>
      </div>

      <Divider />

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={8} lg={5} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/W-Fragen Statue of Liberty.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>

          <Grid item xs={12} lg={7} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Mit W-Fragen gleich zum Ziel
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Neuer, spannender Einstieg zu den Baedeker-Reisezielen
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Divider />
      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={12} lg={7} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Magische Momente
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Ganz großen Zauber erleben: Tauchen Sie ein in eine besondere
              Welt.
            </Typography>
          </Grid>
          <Grid item xs={8} lg={5} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/Magischer Moment.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>
        </Grid>
      </div>

      <Divider />
      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={8} lg={5} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/Kleine Pause.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>
          <Grid item xs={12} lg={7} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              Kleine Pause{' '}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Tipps für genussvolles Entspannen bei jeder Sehenswürdigkeit.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <div style={{ padding: lgUp ? 40 : 20 }}>
        <Grid
          component="section"
          container
          spacing={lgUp ? 10 : 5}
          justify="center"
          className={classes.section}
        >
          <Grid item xs={12} lg={7} className={classes.gridItem}>
            <Typography component="h2" variant="h3" gutterBottom>
              3Ds
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              3D-Grafiken stellen die bekanntesten Sehenswürdigkeiten plastisch
              dar.
            </Typography>
          </Grid>
          <Grid item xs={8} lg={5} className={classes.gridItem}>
            <StaticImage
              className={classes.image}
              src="../../blog/news/images/419-Indien-Taj-Mahal800-460x310.jpg"
              layout="fullWidth"
              alt=""
            />
          </Grid>
        </Grid>
      </div>

      <Divider />
    </Layout>
  )
}
export const smartPageQuery = graphql`
  query SmartPage {
    metaImage: file(relativePath: { eq: "images/content/home/book2.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
export default BaedekerSmart
